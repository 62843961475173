import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box, Dialog, DialogContent, DialogTitle,
  TextField, MenuItem, Button,
} from '@material-ui/core';
import {getOneEndpoint, listFieldsDatabase} from 'api-lofty';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const useStyle = makeStyles(() => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: '#242424',
    padding: '10px',
    color: '#fff',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  inputInfoContainer: {
    padding: '10px',
  },
  inputTitleInfo: {
    fontSize: '15px',
    fontWeight: 600,
    color: '#000',
  },
}));

export default function ModalHandleRedirect({
  data,
  open,
  onClose,
  token,
  idProject,
}) {
  const classes = useStyle();
  const [responsesFromEndpoint, setResponsesFromEndpoint] = useState([]);
  const [paramData, setParamData] = useState([]);
  const [isInvalidResponse, setIsInvalidResponse] = useState(false);

  function translateResponse(value) {
    if (value === 'string') {
      return 'Texto';
    }
    if (value === 'number') {
      return 'Numero';
    }
    if (value === 'Boolean') {
      return 'Booleano';
    }
    return 'Respuesta de Servicio';
  }

  async function handleParamsPageInfo() {
    // Divide Params
    const urlParamsRaw = data?.attributes?.pageRedirect.split('/');
    const filterParams = [];
    urlParamsRaw?.forEach((index) => {
      if (index.includes(':')) {
        const temp = index.split(':');
        const paramData = temp[1];
        filterParams.push({value: paramData, name: paramData});
      }
    });
    setParamData(filterParams);

    const getEndpointInfo = await getOneEndpoint({_id: data?.attributes?.endpointSelect, token});
    if (getEndpointInfo.data.responseType === 'onedatabase') {
      const getFieldsFromDatabase = await listFieldsDatabase({
        idDatabase: getEndpointInfo.data.responseDatabase,
        idProject,
        token,
      });
      const getFieldsList = [];
      getFieldsFromDatabase.data.forEach((index) => (
        getFieldsList.push({_id: index._id, name: index.name, label: index.label})
      ));
      getFieldsList.push({_id: 'idfromdatabase', name: '_id', label: '_id'});
      setResponsesFromEndpoint(getFieldsList);
    } else if (getEndpointInfo.data.responseType === 'listdatabase') {
      toastr.error('Respuesta de servicio es lista. No se puede utilizar');
      setIsInvalidResponse(true);
    } else {
      setResponsesFromEndpoint([{_id: 'n/a', name: getEndpointInfo?.data?.responseType || 'any', label: translateResponse(getEndpointInfo.data.responseType)}]);
    }
  }

  useEffect(() => {
    if (open) {
      handleParamsPageInfo();
    }
  }, [open]);

  function handleChangeValue(index, value) {
    try {
      const findAttributes = data.attributes?.attributes?.redirectWithEndpoint;
      if (findAttributes) {
        const indexOfUpdate = findAttributes.findIndex((obj) => (
          obj.name === index.name
        ));
        if (indexOfUpdate !== -1) {
          findAttributes[indexOfUpdate].param = value;
        } else {
          findAttributes.push({name: index.name, param: value});
        }
        data.addAttributes({redirectWithEndpoint: findAttributes});
      } else {
        const temp = [];
        temp.push({name: index.name, param: value});
        data.addAttributes({redirectWithEndpoint: temp});
      }
      toastr.success('Informacion Actualizada');
    } catch (error) {
      toastr.error('Error al actualizar informacion');
    }
  }

  function findValue(fromOrigin) {
    const findSavedValue = data.attributes?.attributes?.redirectWithEndpoint?.find(
      (index) => (index?.name === fromOrigin.name),
    );
    if (findSavedValue) {
      return findSavedValue.param;
    }
    return '';
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Parametros de Redireccionamiento
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent style={{background: '#242424', color: '#fff'}}>
        <Box
          style={{
            margin: '15px', padding: '5px', color: '#fff', fontSize: '24px',
          }}
        >
          URL:
          {' '}
          {data?.attributes?.pageRedirect}
        </Box>
        <Box
          component="div"
          style={{
            margin: '15px', padding: '5px', height: '400px', overflowY: 'scroll',
          }}
        >
          {!isInvalidResponse && paramData?.map((index) => (
            <Box>
              <div className="my-4">
                <TextField
                  onChange={(e) => handleChangeValue(index, e.target.value)}
                  defaultValue={findValue(index)}
                  select
                  fullWidth
                  name={index.name}
                  type="text"
                  label={index.name}
                  variant="filled"
                  style={{backgroundColor: '#D9D9D9', color: '#fff'}}
                >
                  {
                  responsesFromEndpoint.map(({_id, label, name}) => (
                    <MenuItem value={name} key={_id}>
                      {label}
                    </MenuItem>
                  ))
                }
                </TextField>
              </div>
            </Box>
          ))}
        </Box>
        <Box className={classes.buttonAreaContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
            className={classes.buttonStyle}
          >
            Cerrar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
