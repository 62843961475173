/* eslint-disable max-len */
import {
  getOneDatabase,
  getOneEndpoint,
  listDatabases,
  listFieldsDatabase,
  listParamsEndpoint,
} from 'api-lofty';
import {restoreEndpointTrait, restoreFilterCustomEndpoint} from '../../util/componentDatabaseTraitAndCustom';
import {databaseFieldsGetComponent, getFieldsBucket} from '../../util/databaseFieldsComponent';
import {restoreComponentToDefault} from '../../util/grapeJsCanvasFunctions';
// import {validDestinationForComponent} from '../../util/grapeJsCanvasFunctions';

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;

  async function endpointTraitManager(model, fieldData, typeDB, endpointData) {
    const hasBucket = typeDB.data?.isBucket;
    let fieldFromBucket = '';
    if (hasBucket) {
      fieldFromBucket = getFieldsBucket();
    }
    if (endpointData.data.typeFunction === 'list') {
      const resFieldsReact = databaseFieldsGetComponent(fieldData);
      const structureHtml = `
        <div data-gjs-slugLofty="itemListEndpointDatabase" data-gjs-name="Item List Read" style="padding: 10px;" >
          ${resFieldsReact}
          ${fieldFromBucket}
        </div>
      `;
      model.append(structureHtml);
      // setFieldsOnCarousel(model, structureHtml);
    } else if (endpointData.data.typeFunction === 'customList') {
      const resFieldsReact = databaseFieldsGetComponent(fieldData);
      const structureHtml = `
        <div data-gjs-slugLofty="itemListEndpointDatabase" data-gjs-name="Item List Read" style="padding: 10px;" >
          ${resFieldsReact}
          ${fieldFromBucket}
        </div>
      `;
      model.append(structureHtml);
      // setFieldsOnCarousel(model, structureHtml);
      restoreFilterCustomEndpoint(model, endpointData, opts);
      const {token} = opts;
      // componentData.attributes?.attributes?.carouselParams
      const getParamData = await listParamsEndpoint({
        idEndpoint: endpointData.data._id,
        idProject: endpointData.data.idProject,
        token,
      });
      // console.log(getParamData);
      const paramValues = getParamData.data.map((index) => (
        {
          paramendpoint: {
            value: index.name,
            label: index.label,
            type: index.type,
            stringDefaultValues: index?.stringDefaultValues || [],
          },
          state: index?.stringDefaultValues?.length > 0 ? 'static' : 'useparams',
        }));
      model.addAttributes({carouselParams: paramValues});
    }
  }

  async function handleEndpointForRetrieval(model) {
    try {
      const {token, idProject} = opts;
      const idDatabase = model.attributes.dbSelect;
      const endpoint = await getOneEndpoint({token, _id: model.attributes.endpointSelect});
      const typeDB = await getOneDatabase({token, _id: idDatabase});
      if (endpoint.data.typeFunction.includes('custom') && endpoint.data.responseType === 'listdatabase') {
        const fieldList = await listFieldsDatabase({token, idDatabase: endpoint?.data?.responseDatabase, idProject});
        endpointTraitManager(model, fieldList.data, typeDB, endpoint);
      } else {
        const fieldList = await listFieldsDatabase({token, idDatabase, idProject});
        endpointTraitManager(model, fieldList.data, typeDB, endpoint);
      }
    } catch (error) {
      // console.log(error);
    }
  }

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [{
          type: 'select', // Type of the trait
          label: 'Escritorio', // The label you will see in Settings
          name: 'desktopItems',
          options: [
            {value: 1, name: '1'},
            {value: 2, name: '2'},
            {value: 3, name: '3'},
            {value: 4, name: '4'},
            {value: 5, name: '5'},
            {value: 6, name: '6'},
          ],
          changeProp: 1,
        },
        {
          type: 'select', // Type of the trait
          label: 'Tablet', // The label you will see in Settings
          name: 'tabletItems',
          options: [
            {value: 1, name: '1'},
            {value: 2, name: '2'},
            {value: 3, name: '3'},
            {value: 4, name: '4'},
          ],
          changeProp: 1,
        },
        {
          type: 'select', // Type of the trait
          label: 'Mobile', // The label you will see in Settings
          name: 'mobileItems',
          options: [
            {value: 1, name: '1'},
            {value: 2, name: '2'},
          ],
          changeProp: 1,
        },
        ],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        // console.log(el);
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value === 'singleDynamicCarouselComponent') {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        // console.log(error);
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, '', this.updateButtonState());
        // this.listenTo(model, 'change:variableButton', this.addToVariableEnable);
        this.listenTo(model, 'change:dbSelect', this.handleDatabaseSelection);
        this.listenTo(model, 'change:endpointSelect', this.handleEndpointSelection);
      },
      async updateButtonState() {
        this.model.removeTrait('dbSelect');
        this.model.removeTrait('endpointSelect');
        this.model.removeTrait('buttonCustomEndpointModal');
        this.model.removeTrait('variableButton');
        this.model.removeTrait('arrayOptionState');
        try {
          if (this.model.attributes.sluglofty.includes('singleDynamicCarouselComponent')) {
            // this.model.set({droppable: (e) => isValidItemDrop(e)});
            const {token, idProject} = opts;
            // const idProject = opts.idProject;
            // const idPage = opts.idPage;
            const values = [];
            const resData = await listDatabases({token, idProject});
            resData.data.forEach((index) => values.push({value: index._id, name: index.label}));
            const traitAddition = {
              type: 'select',
              name: 'dbSelect',
              label: 'Seleccion de Coleccion',
              changeProp: 1,
              options: values,
              default: '',
            };
            this.model.addTrait(traitAddition);
            if (this.model.attributes?.dbSelect) {
              restoreEndpointTrait(this.model, 'ReadSingleCarousel', opts, this.model?.attributes?.endpointSelect);
            }
            if (this.model?.attributes?.endpointSelect) {
              const endpoint = await getOneEndpoint({token, _id: this.model.attributes.endpointSelect});
              if (endpoint.data.typeFunction === 'customList') {
                restoreFilterCustomEndpoint(this.model, endpoint, opts);
              }
              // addButtonForVariable(this.model);
            }
            // if (this.model?.attributes?.variableButton) {
            //   addCompatibleVariables(this.model);
            // }
          }
        } catch (error) {
          // console.log(error);
        }
      },
      async handleDatabaseSelection() {
        try {
          if (this.model.attributes.sluglofty.includes('singleDynamicCarouselComponent')) {
            this.model.removeTrait('endpointSelect');
            this.model.removeTrait('buttonCustomEndpointModal');
            this.model.removeTrait('variableButton');
            this.model.removeTrait('arrayOptionState');
            restoreComponentToDefault(this.model);
            restoreEndpointTrait(this.model, 'ReadSingleCarousel', opts, this.model?.attributes?.endpointSelect);
          }
        } catch (error) {
          //
        }
      },
      async handleEndpointSelection() {
        try {
          if (this.model.attributes.sluglofty.includes('singleDynamicCarouselComponent')) {
            this.model.removeTrait('buttonCustomEndpointModal');
            this.model.removeTrait('variableButton');
            this.model.removeTrait('arrayOptionState');
            restoreComponentToDefault(this.model);
            handleEndpointForRetrieval(this.model);
            // addButtonForVariable(this.model);
          }
        } catch (error) {
          //
        }
      },
    }),
  });
};
